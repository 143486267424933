// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "L_v6 d_bD";
export var storyRowWrapper = "L_hx d_hx d_bK";
export var storyLeftWrapper = "L_v7 d_bz d_bP";
export var storyWrapperFull = "L_v8 d_cD";
export var storyWrapperFullLeft = "L_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "L_mv d_hy";
export var storyLeftWrapperCenter = "L_v9 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "L_wb d_hF";
export var storyHeader = "L_wc d_hD d_w d_cs";
export var storyHeaderCenter = "L_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "L_hB d_hB d_by d_dw";
export var storyBtnWrapper = "L_wd d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "L_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "L_rj d_fg d_Z";
export var imageWrapperFull = "L_vd d_w d_H d_bf d_Z";
export var SubtitleSmall = "L_pd z_pd z_sx z_sK";
export var SubtitleNormal = "L_pf z_pf z_sx z_sL";
export var SubtitleLarge = "L_pg z_pg z_sx z_sM";
export var textLeft = "L_dv";
export var textCenter = "L_dw";
export var textRight = "L_dx";