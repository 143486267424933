// extracted by mini-css-extract-plugin
export var alignDiscLeft = "D_vl d_fp d_bG d_dv";
export var alignLeft = "D_rd d_fp d_bG d_dv";
export var alignDiscCenter = "D_vm d_fq d_bD d_dw";
export var alignCenter = "D_bP d_fq d_bD d_dw";
export var alignDiscRight = "D_vn d_fr d_bH d_dx";
export var alignRight = "D_rf d_fr d_bH d_dx";
export var footerContainer = "D_vp d_dW d_bW";
export var footerContainerFull = "D_vq d_dT d_bW";
export var footerHeader = "D_kf d_kf";
export var footerTextWrapper = "D_vr d_w";
export var footerDisclaimerWrapper = "D_km d_km d_ck";
export var badgeWrapper = "D_vs d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "D_vt d_bz d_bJ d_bN d_bL";
export var wide = "D_vv d_cy";
export var right = "D_vw d_bK";
export var line = "D_fk d_fl d_cv";
export var badgeDisclaimer = "D_vx d_bC d_bP d_bJ";
export var badgeContainer = "D_vy d_bz d_bH d_bP";
export var badge = "D_vz";
export var padding = "D_vB d_c7";
export var end = "D_vC d_bH";
export var linkWrapper = "D_vD d_dB";
export var link = "D_mC d_dB";
export var colWrapper = "D_vF d_cx";
export var consent = "D_f d_f d_bC d_bP";
export var disclaimer = "D_vG d_bz d_bJ";
export var media = "D_vH d_bx d_dy";
export var itemRight = "D_vJ";
export var itemLeft = "D_vK";
export var itemCenter = "D_vL";
export var exceptionWeight = "D_sg z_sZ";