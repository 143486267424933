// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "F_vM d_gv d_cs";
export var heroHeaderCenter = "F_gw d_gw d_cs d_dw";
export var heroHeaderRight = "F_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "F_vN d_gr d_cw";
export var heroParagraphCenter = "F_gs d_gs d_cw d_dw";
export var heroParagraphRight = "F_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "F_vP d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "F_vQ d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "F_vR d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "F_vS d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "F_vT d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "F_tq z_tq";
export var heroExceptionNormal = "F_tr z_tr";
export var heroExceptionLarge = "F_ts z_ts";
export var Title1Small = "F_s2 z_s2 z_sx z_sy";
export var Title1Normal = "F_s3 z_s3 z_sx z_sz";
export var Title1Large = "F_s4 z_s4 z_sx z_sB";
export var BodySmall = "F_tg z_tg z_sx z_sR";
export var BodyNormal = "F_th z_th z_sx z_sS";
export var BodyLarge = "F_tj z_tj z_sx z_sT";