// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "C_tS d_bD";
export var datasheetRowWrapper = "C_lt d_lt d_bK";
export var datasheetLeftWrapper = "C_tT d_bz d_bP";
export var datasheetWrapperFull = "C_tV d_cD";
export var datasheetWrapperFullLeft = "C_qT d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "C_mv d_lv";
export var contentWrapperCenter = "C_tW d_lz";
export var datasheetLeftWrapperCenter = "C_tX d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "C_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "C_tY d_lw";
export var datasheetFooter = "C_ly d_ly";
export var alignLeft = "C_rd d_dv";
export var alignCenter = "C_bP d_dw";
export var alignRight = "C_rf d_dx";
export var datasheetInnerWrapperNewLeft = "C_tZ d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "C_t0 d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "C_t1 d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "C_t2 d_bK";
export var datasheetSubtitle = "C_t3 d_cx";
export var tableRow = "C_t4";
export var cell = "C_t5";
export var tableRowWrapper = "C_t6 d_w d_dv";
export var tableHeadingCell = "C_t7";
export var tableHeading = "C_t8";
export var tableRowStriped = "C_t9";
export var tableRowHeading = "C_vb";
export var dataTable = "C_vc d_w";
export var imageWrapper = "C_rj d_fg d_Z";
export var imageWrapperFull = "C_vd d_H d_w d_bf d_Z";
export var imageWrapperIcon = "C_vf";
export var titleMargin = "C_vg d_cs";
export var datasheetInnerInnerWrapper = "C_vh d_w";
export var hasLabels = "C_vj";
export var label = "C_vk";
export var SmallSmall = "C_tl z_tl z_sx z_sV";
export var SmallNormal = "C_tm z_tm z_sx z_sW";
export var SmallLarge = "C_tn z_tn z_sx z_sS";