// extracted by mini-css-extract-plugin
export var customText = "B_tR d_dv d_cs d_cg";
export var videoIframeStyle = "B_nS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "B_rh d_cs d_cg d_Z";
export var customRow = "B_pb d_bD d_bf";
export var quoteWrapper = "B_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "B_nX d_H";
export var masonryImageWrapper = "B_n3";
export var title = "B_r1";
export var Title3Small = "B_s8 z_s8 z_sx z_sG";
export var Title3Normal = "B_s9 z_s9 z_sx z_sH";
export var Title3Large = "B_tb z_tb z_sx z_sJ";