// extracted by mini-css-extract-plugin
export var alignLeft = "v_rd d_fp d_bG d_dv";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignRight = "v_rf d_fr d_bH d_dx";
export var comparisonContainer = "v_sb d_dX";
export var comparisonContainerFull = "v_sc d_dV";
export var comparisonFloatyBox = "v_h1 d_h1 d_by d_Z d_cz";
export var comparisonSubtitle = "v_jb d_jb d_w d_c5";
export var comparisonRow = "v_h6 d_h6 d_cc d_bJ";
export var comparisonMainHeader = "v_h7 d_h7 d_w d_cw";
export var comparisonComponentText = "v_h3 d_h3 d_w";
export var comparisonBtnWrapper = "v_jj d_jj d_d1 d_w d_bz";
export var comparisonBtnWrapperSecond = "v_jk d_jk d_w";
export var comparisonImageContainer = "v_jg d_jg d_Z d_by d_w";
export var stretch = "v_sd";
export var limit = "v_sf";
export var exceptionWeight = "v_sg z_sZ";