// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "K_v2 d_gS d_cw d_dv";
export var quoteParagraphCenter = "K_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "K_gV d_gV d_cw d_dx";
export var quoteRowLeft = "K_v3 d_bG";
export var quoteRowCenter = "K_v4 d_bD";
export var quoteRowRight = "K_v5 d_bH";
export var quoteWrapper = "K_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "K_gR d_gR";
export var quoteExceptionSmall = "K_tx z_tx";
export var quoteExceptionNormal = "K_ty z_ty";
export var quoteExceptionLarge = "K_tz z_tz";
export var quoteAuthorExceptionSmall = "K_tB z_tB";
export var quoteAuthorExceptionNormal = "K_tC z_tC";
export var quoteAuthorExceptionLarge = "K_tD z_tD";